import React, { useState, useEffect } from 'react';
import '../css/StudentOutput.css';

const StudentOutput = () => {
    return (
        <div className="page-container">
            <div className="left-div">
                {/* Dynamic text content goes here */}
                <p>This is dynamic text Output from AI.</p>
                <p>
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content.
        </p>    <p>
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content.
        </p><p>
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content.
        </p><p>
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content. This is dynamic text content.
          This is dynamic text content. This is dynamic text content.
        </p>
            </div>
            <div className="right-div">
                {/* Content for the smaller right div */}
                <p>Topics to be studied.</p>
            </div>
        </div>
    )
}

export default StudentOutput  